import React, { useContext, useEffect, useMemo } from "react";
import { isEmpty } from "lodash";
import moment from "moment";
import { evaluate } from "mathjs";

import AdminBar from "../../components/AdminBar";

import { sendNotification } from "../../helpers/notification";

import { AdminContext } from "../../context/admin-context";
import { UserContext } from "../../context/users-context";

import { AlignJustify, Plus} from "react-feather";

const Goldex = (props) => {
  const adminCunsumer = useContext(AdminContext);
  const userCunsumer = useContext(UserContext);
  const [showTake, setShowTake] = React.useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await adminCunsumer.getProviderStats('7SBER');
    }
    fetchData();
    return () => adminCunsumer.clear();
  }, []);

  useEffect(() => {
    if (adminCunsumer.redirect === true) {
      sendNotification("warning", "Ошибка ⚠️", adminCunsumer.redirectMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminCunsumer.redirect]); 

  const hendler = async (event) => {
    await adminCunsumer.updateState('provider_summary', { ...adminCunsumer.provider_summary, [event.target.name]: event.target.value });
  };

  const addSummary = async () => {
    await adminCunsumer.addProviderSummary("7SBER");
  }

  const ballanceHendler = async (event) => {
    await adminCunsumer.updateState('provider_ballance', event.target.value);
  };

  const addProviderBallance = async () => {
    await adminCunsumer.addProviderBallance("7SBER");
  }

  const takeHendler = async (event) => {
    await adminCunsumer.updateState('take_amount', event.target.value);
  };

  const takeProfit = async () => {
    await adminCunsumer.takeProfit("7SBER");
  }

  const getProfitAmount = () => {
    const profit = evaluate(`${adminCunsumer.provider_stats.providerTotalsUsdt} - ${adminCunsumer.provider_stats.totalsUsdt}`);
    
    if (!isEmpty(adminCunsumer.provider_stats.takedProfit)) {
      const taken = adminCunsumer.provider_stats.takedProfit.history.reduce((acc, item) => { return acc + item.amount }, 0);
      return evaluate(`${profit} - ${taken}`).toFixed(2);
    } else {
      return profit.toFixed(2);
    }
  }

  const getProviderAmount = () => {
    const profit = adminCunsumer.provider_stats.totalsUsdt;

    if (!isEmpty(adminCunsumer.provider_stats.takedProfit)) {
      const taked = adminCunsumer.provider_stats.takedProfit.history.reduce((acc, item) => { return acc + item.amount }, 0);
      return evaluate(`${profit} + ${taked}`).toFixed(2);
    } else {
      return profit.toFixed(2);
    }
  }

  return (
    <>
      <div className="main-wrapper">
        <AdminBar />
        <div className="page-wrapper" style={{backgroundColor: '#070d19'}}>
          <nav  className="navbar" style={{height: '60px'}}>
            <div  className="navbar-content">
              <form  className="search-form">
                <div  className="input-group">
                  <div className=" d-flex justify-content-between align-items-center flex-wrap w-100">
                    <div>
                      <span className="mt-4 mb-md-0 sub-title media-fs-17" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                        <AlignJustify className="link-icon mb-1 me-3 icon-menu-mobil-media" style={{width: '18px'}} onClick = { () => {userCunsumer.setSidebarOpen(true)}}/>
                        Свод провайдера 7SBER
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </nav>
          <div className="page-content">
            <div className="row flex-grow">
              <div className="col-md-4 grid-margin stretch-card">
                <div className="card" style={{ backgroundColor: 'rgb(8 51 68)' }}>
                  { !isEmpty(adminCunsumer.provider_stats) ? (
                    <div className="card-body">
                      <h5 className="text-uppercase mb-2">Касса RUB:</h5>
                      <h3 className="mb-2">{ (adminCunsumer.provider_stats.totalsRub).toFixed(2) }</h3>
                      <hr className="my-3" />
                      <p className="text-white fw-bold">Сумма всех успехов: { (adminCunsumer.provider_stats.ordersStat.total_rub).toFixed(2) } руб.</p>
                    </div>
                  ) : (
                    <div className="card-body placeholder-glow">
                      <h5 className="mb-2 placeholder-glow">
                        <span className="placeholder col-6 rounded-pill"></span>
                      </h5>

                      <h3 className="mb-2 placeholder-glow"><span className="placeholder col-6 rounded-pill"></span></h3>
                      <hr className="my-3" />
                      <p className="placeholder-glow"><span className="placeholder w-100 rounded-pill"></span></p>
                    </div>
                  )}
                  
                </div>
              </div>
              <div className="col-md-4 grid-margin stretch-card">
                <div className="card" style={{ backgroundColor: 'rgb(216 216 216 / 36%)' }}>
                { !isEmpty(adminCunsumer.provider_stats) ? (
                  <div className="card-body">
                    <h5 className="text-uppercase mb-2">Касса USDT 7SBER:</h5>
                    <h3 className="mb-2">{ (adminCunsumer.provider_stats.providerTotalsUsdt).toFixed(2) }</h3>
                    <hr className="my-3" />
                    <h5 className="text-uppercase mb-2">Касса USDT Cypix:</h5>
                    <h3 className="mb-2">{ getProviderAmount() }</h3>
                    <hr className="my-3" />
                    <p className="text-white fw-bold">Сумма всех успехов: { (adminCunsumer.provider_stats.ordersStat.total_usdt).toFixed(2) } usdt</p>
                  </div>
                ) : (
                  <div className="card-body placeholder-glow">
                    <h5 className="mb-2 placeholder-glow">
                      <span className="placeholder col-6 rounded-pill"></span>
                    </h5>

                    <h3 className="mb-2 placeholder-glow"><span className="placeholder col-6 rounded-pill"></span></h3>
                    <hr className="my-3" />
                    <p className="placeholder-glow"><span className="placeholder w-100 rounded-pill"></span></p>
                  </div>
                )}
                </div>
              </div>
              <div className="col-md-4 grid-margin stretch-card">
                <div className="card" style={{ backgroundColor: 'rgb(2 44 34)' }}>
                { !isEmpty(adminCunsumer.provider_stats) ? (
                  <div className="card-body">
                    <h5 className="text-uppercase mb-2">Баланс:</h5>
                    <h3 className="mb-2">{ (adminCunsumer.provider_stats.ballance).toFixed(2) }</h3>
                    <hr className="my-3" />
                    <h5 className="text-uppercase mb-2">Доход:</h5>
                    <h4 className="mb-2">{ getProfitAmount() } USDT</h4>
                    <h4 className="mb-2">{ evaluate(`${getProfitAmount()} * ${adminCunsumer.provider_stats.course}`).toFixed(2) } RUB</h4>
                    { !showTake && (
                      <button className="btn custom-btn" onClick={() => setShowTake(true)}>
                        Забрать доход <span className="icon">💰</span>
                      </button>
                    )}
                    
                  </div>
                ) : (
                  <div className="card-body placeholder-glow">
                    <h5 className="mb-2 placeholder-glow">
                      <span className="placeholder col-6 rounded-pill"></span>
                    </h5>

                    <h3 className="mb-2 placeholder-glow"><span className="placeholder col-6 rounded-pill"></span></h3>
                    <hr className="my-3" />
                  </div>
                )}
                </div>
              </div>

              <div className="col-md-4 grid-margin stretch-card">
                <div className="card" style={{ backgroundColor: '' }}>
                  <div className="card-body">
                    <h6 className="text-uppercase mb-2">Добавить корректировку:</h6>
                    <hr className="my-3" />
                    
                    <div className="form-group">
                      <label htmlFor="rub" style={{fontSize: 14, color: '#fff'}}>
                        <b>Сумма RUB</b>
                      </label>
                      <input
                        type="number"
                        name="rub"
                        onChange={hendler}
                        value={adminCunsumer.provider_summary.rub || ''}
                        required
                        className="form-control"
                        id="rub"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="usdt" style={{fontSize: 14, color: '#fff'}}>
                        <b>Сумма USDT</b>
                      </label>
                      <input
                        type="number"
                        name="usdt"
                        onChange={hendler}
                        value={adminCunsumer.provider_summary.usdt || ''}
                        required
                        className="form-control"
                        id="usdt"
                      />
                    </div>

                    <div className="form-group">
                     {
                        adminCunsumer.provider_summary.rub && adminCunsumer.provider_summary.usdt ?
                        <span className="text-success fw-bold">Полученый курс: { (evaluate(`${adminCunsumer.provider_summary.rub} / ${adminCunsumer.provider_summary.usdt}`)).toFixed(2) }</span>
                        : <span className="text-warning">Курс: введите суммы</span>
                     }
                    </div>
                    
                    <div className="mt-3">
                      <button
                        className={`btn btn-line-primary btn-line-primary-hover btn-sm py-2 px-4 mt-3`}
                        onClick={addSummary}
                      >
                        Добавить корректировку{" "}
                        <Plus className="link-icon ms-1 mb-1" style={{width: '16px'}}/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-md-4 grid-margin stretch-card">
                <div className="card" style={{ backgroundColor: '' }}>
                  <div className="card-body">
                    <h6 className="text-uppercase mb-2">Добавить балланс:</h6>
                    <hr className="my-3" />
                    <div className="form-group">
                      <label htmlFor="usdt" style={{fontSize: 14, color: '#fff'}}>
                        <b>Сумма USDT</b>
                      </label>
                      <input
                        type="number"
                        name="usdtProvider"
                        onChange={ballanceHendler}
                        value={adminCunsumer.provider_ballance || ''}
                        required
                        className="form-control"
                        id="usdtProvider"
                      />
                    </div>
                    
                    <div className="mt-3">
                      <button
                        className={`btn btn-line-primary btn-line-primary-hover btn-sm py-2 px-4 mt-3`}
                        onClick={addProviderBallance}
                      >
                        Добавить балланс{" "}
                        <Plus className="link-icon ms-1 mb-1" style={{width: '16px'}}/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              { showTake && (
                <div className="col-md-4 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <h6 className="text-uppercase mb-2">Забрать доход:</h6>
                      <hr className="my-3" />
                      <div className="form-group">
                        <label htmlFor="take_amount" style={{fontSize: 14, color: '#fff'}}>
                          <b>Сумма в USDT, которую забираем:</b>
                        </label>
                        <input
                          type="number"
                          name="take_amount"
                          onChange={takeHendler}
                          value={adminCunsumer.take_amount || ''}
                          required
                          className="form-control"
                          id="take_amount"
                        />
                      </div>
                      
                      <div className="mt-3">
                        <button
                          className={`btn btn-line-primary btn-line-primary-hover btn-sm py-2 px-4 mt-3`}
                          onClick={takeProfit}
                        >
                          Забрать
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <hr className="mt-2 mb-4" />
            <div className="row">
              <div className="col-md-5 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h6 className="card-title">История сводок</h6>
                    <div className="table-responsive">
                      <table className="table table-hover summary">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Сумма RUB</th>
                            <th>Сумма USDT</th>
                            <th>Дата</th>
                          </tr>
                        </thead>
                        <tbody>
                          { !isEmpty(adminCunsumer.provider_stats.summaries) ? (
                            adminCunsumer.provider_stats.summaries.map((item, index) => (
                              <tr key={index}>
                                <td>{item.id}</td>
                                <td>{item.rub} руб.</td>
                                <td>{item.usdt} usdt</td>
                                <td>{moment(item.createdAt).format("DD.MM.YYYY HH:mm")}</td>
                              </tr>
                            ))
                          ) : (
                            <>
                              <tr className="placeholder-glow">
                                <th><span className="placeholder w-100 rounded-pill" /></th>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                              </tr>
                              <tr className="placeholder-glow">
                                <th><span className="placeholder w-100 rounded-pill" /></th>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                              </tr>
                              <tr className="placeholder-glow">
                                <th><span className="placeholder w-100 rounded-pill" /></th>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                              </tr>
                              <tr className="placeholder-glow">
                                <th><span className="placeholder w-100 rounded-pill" /></th>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                              </tr>
                            </>
                          )}
                          
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3 grid-margin stretch-card">
                <div className="card" style={{ backgroundColor: 'rgb(12 36 39)' }}>
                  <div className="card-body">
                    <h6 className="card-title">История добавлений баланса</h6>
                    <div className="table-responsive">
                      <table className="table table-hover summary">
                        <thead>
                          <tr>
                            <th>Сумма USDT</th>
                            <th>Дата</th>
                          </tr>
                        </thead>
                        <tbody>
                          { adminCunsumer.provider_stats.metadata && !isEmpty(adminCunsumer.provider_stats.metadata.history) ? (
                            adminCunsumer.provider_stats.metadata.history.map((item, index) => (
                              <tr key={index}>
                                <td>{item.amount} usdt</td>
                                <td>{moment(item.date).format("DD.MM.YYYY HH:mm")}</td>
                              </tr>
                            ))
                          ) : (
                            <>
                              <tr className="placeholder-glow">
                                <th><span className="placeholder w-100 rounded-pill" /></th>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                              </tr>
                              <tr className="placeholder-glow">
                                <th><span className="placeholder w-100 rounded-pill" /></th>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                              </tr>
                              <tr className="placeholder-glow">
                                <th><span className="placeholder w-100 rounded-pill" /></th>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                              </tr>
                              <tr className="placeholder-glow">
                                <th><span className="placeholder w-100 rounded-pill" /></th>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                              </tr>
                            </>
                          )}
                          
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 grid-margin stretch-card">
                <div className="card" style={{ backgroundColor: 'rgb(21 32 21)' }}>
                  <div className="card-body">
                    <h6 className="card-title">История снятия дохода</h6>
                    <div className="table-responsive">
                      <table className="table table-hover summary">
                        <thead>
                          <tr>
                            <th>Сумма USDT</th>
                            <th>Дата</th>
                          </tr>
                        </thead>
                        <tbody>
                          { adminCunsumer.provider_stats.takedProfit && !isEmpty(adminCunsumer.provider_stats.takedProfit.history) ? (
                            adminCunsumer.provider_stats.takedProfit.history.map((item, index) => (
                              <tr key={index}>
                                <td>{item.amount} usdt</td>
                                <td>{moment(item.date).format("DD.MM.YYYY HH:mm")}</td>
                              </tr>
                            ))
                          ) : (
                            <>
                              <tr className="placeholder-glow">
                                <th><span className="placeholder w-100 rounded-pill" /></th>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                              </tr>
                              <tr className="placeholder-glow">
                                <th><span className="placeholder w-100 rounded-pill" /></th>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                              </tr>
                              <tr className="placeholder-glow">
                                <th><span className="placeholder w-100 rounded-pill" /></th>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                              </tr>
                              <tr className="placeholder-glow">
                                <th><span className="placeholder w-100 rounded-pill" /></th>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                              </tr>
                            </>
                          )}
                          
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Goldex;