import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import {AlignJustify} from "react-feather";
import { isEmpty } from "lodash";

import { sendNotification } from "../../helpers/notification";
import AdminBar from "../../components/AdminBar";
import TwoFa from "../../components/TwoFa";
import { UserContext } from "../../context/users-context";
import { AdminContext } from "../../context/admin-context";
import Loader from "../../components/Loader";

const AdminSettings = () => {

  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);
  const [redirectPath, setRedirectPath] = React.useState("");
  const userCunsumer = useContext(UserContext);
  const adminCunsumer = useContext(AdminContext);

  useEffect(() => {
    adminCunsumer.getSettings();
    setLoading(false);
  }, []);

  useEffect(() => {
    if (userCunsumer.redirect === true) {
      sendNotification("warning", "Ошибка ⚠️", userCunsumer.redirectMessage);
      setRedirectPath(userCunsumer.redirectPath);
      userCunsumer.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCunsumer.redirect]); 

  const renderRedirect = () => {
    if (redirect && redirectPath) {
      return (
        <Redirect
          to={{
            pathname: redirectPath,
          }}
        />
      );
    }
  };

  const updateSettings = (e) => {
    adminCunsumer.updateSettingsValue(e.target.name, e.target.value);
  }

  const submitForm = (e) => {
    e.preventDefault();
    adminCunsumer.updateSettings()
    .then(() => {
      sendNotification("success", "Успешно", "Настройки успешно обновлены");
    });
  }
  
  const setCard = async (event) => {
    await adminCunsumer.updateState(event.target.name, event.target.value);
  };

  const addCard = (e) => {
    e.preventDefault();
    if (isEmpty(adminCunsumer.card_to_debt)) {
			sendNotification("danger", "Ошибка", `Карта не может быть пустой`);
			return;
		}

    adminCunsumer.addCardToDebt();
  }

  const deleteCard = async (card) => {
    await adminCunsumer.deleteCardFromDebt(card);
  }

  return (
    <>
      {renderRedirect()}
      {loading && <p>Loading</p>}
      <div className="main-wrapper">
        <AdminBar />
        <div className="page-wrapper" style={{backgroundColor: '#070d19'}}>
          <nav  className="navbar" style={{height: '60px'}}>
            <div  className="navbar-content">
              <form  className="search-form">
                <div  className="input-group">
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <div>
                      <span className="mt-4 mb-md-0 sub-title" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                        <AlignJustify className="link-icon mb-1 me-3 icon-menu-mobil-media" style={{width: '18px'}} onClick = { () => {userCunsumer.setSidebarOpen(true)}}/>
                        Настройки
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </nav>
          <div className="page-content">
            <TwoFa />

            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-4">
                    {isEmpty(adminCunsumer.settings) ? (
                      <Loader />
                    ) : (
                      <>
                        <div className="form-group">
                          <label style={{ fontSize: 16, color: '#a4b4c3' }} htmlFor="goldexProfit">
                            <strong>% Goldex:</strong>
                          </label>
                          <input
                            type="number"
                            onChange={updateSettings}
                            className="form-control"
                            name="goldexProfit"
                            value={adminCunsumer.settings.goldexProfit}
                            id="goldexProfit"
                            // placeholder={"% Goldex"}
                          />
                        </div>
                        <div className="form-group">
                          <label style={{ fontSize: 16, color: '#a4b4c3' }} htmlFor="baybitStep">
                            <strong>Позиция байбит:</strong>
                          </label>
                          <div className="alert alert-warning px-3 py-1 fw-bold" style={{ fontSize: 13 }}>
                            Позиция байбит номеруется с 0: <br /> 0 - первая позиция, 1 - вторая позиция и т.д.
                          </div>
                          <input
                            type="number"
                            onChange={updateSettings}
                            className="form-control"
                            name="baybitStep"
                            value={adminCunsumer.settings.baybitStep}
                            id="baybitStep"
                            // placeholder={"Позиция байбит"}
                          />
                        </div>

                        <div className="form-group form-group px-3 pt-2 rounded-xl pb-1" style={{ backgroundColor: '#1e2a47' }}>
                          <label style={{ fontSize: 16, color: '#a4b4c3' }}>
                            <strong>Атоматическая выплата:</strong>
                          </label>
                          <div className="form-check">
                            <label className="form-check-label">
                              <input type="radio" className="form-check-input" name="autoPayment" onChange={updateSettings} value={true} checked={[true, 'true'].includes(adminCunsumer.settings.autoPayment)} />
                              Включено
                            <i className="input-frame"></i></label>
                          </div>
                          <div className="form-check">
                            <label className="form-check-label">
                              <input type="radio" className="form-check-input" name="autoPayment" onChange={updateSettings} value={false} checked={[false, 'false'].includes(adminCunsumer.settings.autoPayment)} />
                              Отключено
                            <i className="input-frame"></i></label>
                          </div>
                        </div>

                        <button
                          style={{fontSize: '12px'}}
                          onClick={(e) => submitForm(e)}
                          className={`btn media-btn-sm btn-two p-3 d-flex align-items-center justify-content-center`}
                        >
                          Сохранить <i className="mdi mdi-check"></i>
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <section className="mt-4">
              <div className="row d-flex justify-content-start">
                {isEmpty(adminCunsumer.settings) ? (
                  <>
                    <div className="col-md-4 placeholder-glow">
                      <span className="placeholder w-100 rounded-pill" />
                      <span className="placeholder w-100 rounded-pill" />
                      <span className="placeholder w-100 rounded-pill" />
                      <span className="placeholder w-100 rounded-pill" />
                    </div>
                    <div className="col-md-6 placeholder-glow">
                      <span className="placeholder w-100 rounded-pill" />
                      <span className="placeholder w-100 rounded-pill" />
                      <span className="placeholder w-100 rounded-pill" />
                      <span className="placeholder w-100 rounded-pill" />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-4 grid-margin stretch-card">
                      <div className="card">
                        <div style={{padding: '20px'}}  className="card-body pb-1" >
                          <h6 className="card-title-light">
                            Карты в черном списке ⛔️ 
                          </h6>
                          <hr className="mt-3 mb-4"/>
                          { !isEmpty(adminCunsumer.settings.debtList) ? (
                            adminCunsumer.settings.debtList.map((item, index) => {
                              return <p key={index} className="mb-1 text-white">{item} - <span className="text-muted" onClick={() => deleteCard(item)} style={{ fontSize: '15px'}} role="button" tabindex="0">удалить</span></p>
                            })
                          ) : (
                            <div className="placeholder-glow">
                              <h6>Спикос пока что пустой.</h6>
                              <span className="placeholder w-100 rounded-pill" />
                              <span className="placeholder w-100 rounded-pill" />
                              <span className="placeholder w-100 rounded-pill" />
                              <span className="placeholder w-100 rounded-pill" />
                              <span className="placeholder w-100 rounded-pill" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 grid-margin stretch-card">
                      <div className="card">
                        <div style={{padding: '20px'}}  className="card-body pb-1" >
                          <h6 className="">
                            Добавить карту в черный список 
                          </h6>
                          <hr className="mt-3 mb-4"/>
                          <form
                            className="forms-sample"
                            onSubmit={(e) => e.preventDefault()}
                          >
                            <div className="form-group">
                              <label style={{color: '#eef4ff'}}  htmlFor="card_to_debt">
                                <b>Карта</b>
                              </label>
                              <input
                                type="text"
                                name="card_to_debt"
                                onChange={setCard}
                                required
                                placeholder=""
                                className="form-control"
                                value={adminCunsumer.card_to_debt ? adminCunsumer.card_to_debt : '' }
                                />
                            </div>
                            <div className="mt-3">
                              <button
                                style={{fontSize: '12px'}}
                                onClick={(e) => addCard(e)}
                                className={`btn media-btn-sm btn-two p-3 d-flex align-items-center justify-content-center`}
                              >
                                Добавить <i className="mdi mdi-check"></i>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSettings;
