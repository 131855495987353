import React, { useContext, useEffect, useMemo } from "react";
import { isEmpty } from "lodash";
import moment from "moment";
import { evaluate } from "mathjs";

import AdminBar from "../../components/AdminBar";

import { sendNotification } from "../../helpers/notification";

import { AdminContext } from "../../context/admin-context";
import { UserContext } from "../../context/users-context";

import { AlignJustify, Plus, Eye, EyeOff} from "react-feather";

const Summary = (props) => {
  const adminCunsumer = useContext(AdminContext);
  const userCunsumer = useContext(UserContext);
  const [formulaDisplay, setFormulaDisplay] = React.useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await adminCunsumer.getTotals();
      await adminCunsumer.getSummaries();
    }
    fetchData();
    return () => adminCunsumer.clear();
  }, []);

  useEffect(() => {
    if (adminCunsumer.redirect === true) {
      sendNotification("warning", "Ошибка ⚠️", adminCunsumer.redirectMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminCunsumer.redirect]); 

  const hendler = async (event) => {
    await adminCunsumer.updateState('summary', { ...adminCunsumer.summary, [event.target.name]: event.target.value });
  };

  const addSummary = async () => {
    await adminCunsumer.addSummary();
  }

  const GetFormula = ({total, summaries, adjustedTotal, currency_key}) => {
    const formula = useMemo(() => {
      const sortedSummaries = [...summaries].sort((a, b) => a.id - b.id);
  
      const { summ, data } = sortedSummaries.reduce(
        (acc, item, index) => {
          acc.summ += item[currency_key];
          acc.data.push(
            <span key={item.id}>
              {item[currency_key]}
              {index < sortedSummaries.length - 1 && ' - '}
            </span>
          );
          return acc;
        },
        { summ: 0, data: [] }
      );
  
      const result = evaluate(`${total} - ${summ}`).toFixed(2);
      const checker = result !== adjustedTotal;
  
      return (
        <>
          <p className="mb-1">
            {total} - ({data})
            <span className={`fw-bold ${checker ? 'text-danger' : 'text-success'}`}>
              {' '}= {result} {currency_key}
            </span>
          </p>
          {checker && (
            <p className="text-danger mb-0">Формула не совпадает с кассой. Проверьте.</p>
          )}
        </>
      );
    }, [total, summaries, adjustedTotal, currency_key]);
  
    return formula;
  };

  return (
    <>
      <div className="main-wrapper">
        <AdminBar />
        <div className="page-wrapper" style={{backgroundColor: '#070d19'}}>
          <nav  className="navbar" style={{height: '60px'}}>
            <div  className="navbar-content">
              <form  className="search-form">
                <div  className="input-group">
                  <div className=" d-flex justify-content-between align-items-center flex-wrap w-100">
                    <div>
                      <span className="mt-4 mb-md-0 sub-title media-fs-17" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                        <AlignJustify className="link-icon mb-1 me-3 icon-menu-mobil-media" style={{width: '18px'}} onClick = { () => {userCunsumer.setSidebarOpen(true)}}/>
                        Свод кассы
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </nav>
          <div className="page-content">
            <div className="row flex-grow">
              <div className="col-md-4 grid-margin stretch-card">
                <div className="card" style={{ backgroundColor: 'rgb(8 51 68)' }}>
                  { !isEmpty(adminCunsumer.totals) ? (
                    <div className="card-body">
                      <h5 className="text-uppercase mb-2">Касса RUB:</h5>
                      <h3 className="mb-2">{ (adminCunsumer.totals.adjustedTotalRub).toFixed(2) }</h3>
                      <hr className="my-3" />
                      <p className="text-white fw-bold">Сумма всех успехов: { (adminCunsumer.totals.totalRub).toFixed(2) } руб.</p>
                      <div className="px-3 py-2 rounded-3" style={{ backgroundColor: 'rgb(22 78 99)' }}>
                        <p className="text-white border-bottom pb-1 mb-1 d-flex justify-content-between">
                          Формула: 
                          {
                            formulaDisplay ? (
                              <EyeOff onClick={() => setFormulaDisplay(false)} className="link-icon ms-1 mb-1 hover-pointer" style={{width: '20px'}}/>
                            ) : (
                              <Eye onClick={() => setFormulaDisplay(true)} className="link-icon ms-1 mb-1 hover-pointer" style={{width: '20px'}}/>
                            )
                          }
                        </p>
                        { formulaDisplay && 
                          <GetFormula total={adminCunsumer.totals.totalRub} summaries={adminCunsumer.SummariesData} adjustedTotal={(adminCunsumer.totals.adjustedTotalRub).toFixed(2)} currency_key="rub" />
                        }
                      </div>
                    </div>
                  ) : (
                    <div className="card-body placeholder-glow">
                      <h5 className="mb-2 placeholder-glow">
                        <span className="placeholder col-6 rounded-pill"></span>
                      </h5>

                      <h3 className="mb-2 placeholder-glow"><span className="placeholder col-6 rounded-pill"></span></h3>
                      <hr className="my-3" />
                      <p className="placeholder-glow"><span className="placeholder w-100 rounded-pill"></span></p>
                      <div className="placeholder-glow">
                        <span className="placeholder w-100 rounded-lg" style={{ height: '150px'}}></span>
                      </div>
                    </div>
                  )}
                  
                </div>
              </div>
              <div className="col-md-4 grid-margin stretch-card">
                <div className="card" style={{ backgroundColor: 'rgb(2 44 34)' }}>
                { !isEmpty(adminCunsumer.totals) ? (
                  <div className="card-body">
                    <h5 className="text-uppercase mb-2">Касса USDT:</h5>
                    <h3 className="mb-2">{ (adminCunsumer.totals.adjustedTotalUsdt).toFixed(2) }</h3>
                    <hr className="my-3" />
                    <p className="text-white fw-bold">Сумма всех успехов: { (adminCunsumer.totals.totalUsdt).toFixed(2) } usdt</p>
                    <div className="px-3 py-2 rounded-3" style={{ backgroundColor: 'rgb(6 78 59)' }}>
                      <p className="text-white border-bottom pb-1 mb-1 d-flex justify-content-between">
                        Формула: 
                        {
                          formulaDisplay ? (
                            <EyeOff onClick={() => setFormulaDisplay(false)} className="link-icon ms-1 mb-1 hover-pointer" style={{width: '20px'}}/>
                          ) : (
                            <Eye onClick={() => setFormulaDisplay(true)} className="link-icon ms-1 mb-1 hover-pointer" style={{width: '20px'}}/>
                          )
                        }
                      </p>
                      { formulaDisplay &&
                        <GetFormula total={adminCunsumer.totals.totalUsdt} summaries={adminCunsumer.SummariesData} adjustedTotal={(adminCunsumer.totals.adjustedTotalUsdt).toFixed(2)} currency_key="usdt" />
                      }
                    </div>
                  </div>
                ) : (
                  <div className="card-body placeholder-glow">
                    <h5 className="mb-2 placeholder-glow">
                      <span className="placeholder col-6 rounded-pill"></span>
                    </h5>

                    <h3 className="mb-2 placeholder-glow"><span className="placeholder col-6 rounded-pill"></span></h3>
                    <hr className="my-3" />
                    <p className="placeholder-glow"><span className="placeholder w-100 rounded-pill"></span></p>
                    <div className="placeholder-glow">
                      <span className="placeholder w-100 rounded-lg" style={{ height: '150px'}}></span>
                    </div>
                  </div>
                )}
                </div>
              </div>

              <div className="col-md-4 grid-margin stretch-card">
                <div className="card" style={{ backgroundColor: '' }}>
                  <div className="card-body">
                    <h6 className="text-uppercase mb-2">Добавить корректировку:</h6>
                    <hr className="my-3" />
                    
                    <div className="form-group">
                      <label htmlFor="rub" style={{fontSize: 14, color: '#fff'}}>
                        <b>Сумма RUB</b>
                      </label>
                      <input
                        type="number"
                        name="rub"
                        onChange={hendler}
                        value={adminCunsumer.summary.rub || ''}
                        required
                        className="form-control"
                        id="rub"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="usdt" style={{fontSize: 14, color: '#fff'}}>
                        <b>Сумма USDT</b>
                      </label>
                      <input
                        type="number"
                        name="usdt"
                        onChange={hendler}
                        value={adminCunsumer.summary.usdt || ''}
                        required
                        className="form-control"
                        id="usdt"
                      />
                    </div>

                    <div className="form-group">
                     
                     {
                        adminCunsumer.summary.rub && adminCunsumer.summary.usdt ?
                        <span className="text-success fw-bold">Полученый курс: { (evaluate(`${adminCunsumer.summary.rub} / ${adminCunsumer.summary.usdt}`)).toFixed(2) }</span>
                        : <span className="text-warning">Курс: введите суммы</span>
                     }
                    </div>
                    
                    <div className="mt-3">
                      <button
                        className={`btn btn-line-primary btn-line-primary-hover btn-sm py-2 px-4 mt-3`}
                        onClick={addSummary}
                      >
                        Добавить корректировку{" "}
                        <Plus className="link-icon ms-1 mb-1" style={{width: '16px'}}/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mt-2 mb-4" />
            <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h6 className="card-title">История сводок</h6>
                    <div className="table-responsive">
                      <table className="table table-hover summary">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Сумма RUB</th>
                            <th>Сумма USDT</th>
                            <th className="text-info">RUB до сводки</th>
                            <th className="text-success">RUB после сводки</th>
                            <th className="text-info">USDT до сводки</th>
                            <th className="text-success">USDT после сводки</th>
                            <th>Дата</th>
                          </tr>
                        </thead>
                        <tbody>
                          { !isEmpty(adminCunsumer.SummariesData) ? (
                            adminCunsumer.SummariesData.map((item, index) => (
                              <tr key={index}>
                                <td>{item.id}</td>
                                <td>{item.rub} руб.</td>
                                <td>{item.usdt} usdt</td>
                                <td>{(item.metadata.beforeTotalsRub).toFixed(2)} руб.</td>
                                <td>{(item.metadata.afterTotalsRub).toFixed(2)} руб.</td>
                                <td>{(item.metadata.beforeTotalsUsdt).toFixed(2)} usdt</td>
                                <td>{(item.metadata.afterTotalsUsdt).toFixed(2)} usdt</td>
                                <td>{moment(item.createdAt).format("DD.MM.YYYY HH:mm")}</td>
                              </tr>
                            ))
                          ) : (
                            <>
                              <tr className="placeholder-glow">
                                <th><span className="placeholder w-100 rounded-pill" /></th>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                              </tr>
                              <tr className="placeholder-glow">
                                <th><span className="placeholder w-100 rounded-pill" /></th>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                              </tr>
                              <tr className="placeholder-glow">
                                <th><span className="placeholder w-100 rounded-pill" /></th>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                              </tr>
                              <tr className="placeholder-glow">
                                <th><span className="placeholder w-100 rounded-pill" /></th>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                                <td><span className="placeholder w-100 rounded-pill" /></td>
                              </tr>
                            </>
                          )}
                          
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Summary;