import React, { useContext, useState, useEffect } from "react";
import { AdminContext } from "../../context/admin-context";
import { OrderContext } from "../../context/orders-context";
import { sendNotification } from "../../helpers/notification";
import { isEmpty } from "lodash";
import Loader from "../Loader";



const ChangeStatusModal = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [order, setOrder] = React.useState();

  const adminCunsumer = useContext(AdminContext);
  const OrderCunsumer = useContext(OrderContext);
  
  const { show } = props;

  useEffect(() => {
    setLoading(false);
  }, [loading]);

  useEffect(() => {
    if (!isEmpty(adminCunsumer.activeOrder)) {
      let order = OrderCunsumer.orders.filter((obj) => {
        return obj.id === adminCunsumer.activeOrder;
      });
      setOrder(order[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminCunsumer.activeOrder]); 

  const updateField = async (event) => {
    await adminCunsumer.updateState(event.target.name, event.target.value);
  };

  const getStatus = (status) => {
    switch (status) {
      case "COMPLETED":
        return "Выполненная"
      case "PENDING":
        return "В работе"
      case "ERROR":
        return "Ошибочная"
      default:
        return "Новая"
    }
  }

  const submit = async () => {
    return adminCunsumer
      .updateOrder()
      .then(props.updateData())
      .then(simulateClick.current.click())
      .catch((e) => {
        console.log("e", JSON.stringify(e));
        if (e.response && e.response.body.message) {
          sendNotification("danger", "Ошибка ⚠️", e.response.body.message);
        }
      });
  };

  const simulateClick = React.useRef(null);

  return (
    <>
      {loading ? (
        <Loader/>
      ) : (
        <div
          className={`modal fade ${show === true ? "show" : "d-none"}`}
          tabIndex="-1"
          style={{overflow: 'scroll'}}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content  p-3">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Смена статуса заявки id:{order && order.id}
                </h5>
                <button
                  type="button"
                  onClick={props.togglePopUp}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span style={{color: 'white'}} aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-white">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group" style={{ fontSize: 16 }}>
                        <p className="mb-1">ID заявки: {order && order.id}</p>
                        <p className="mb-1 fw-bold">Внешний ID: {order && order.externalId}</p>
                        <p className="mb-1">
                          <strong>
                            Текущий статус: { order && getStatus(order.status) }
                          </strong>
                        </p>
                        <p className="mb-1">ФИО: {order && order.clientName}</p>
                        <p className="mb-1">Карта: {order && order.card}</p>
                        <p className="mb-1">Сумма: {order && order.amount}</p>
                        <p className="mb-1">Банк: {order && order.bank}</p>
                        <p className="mb-1">Сумма в USDT: {order && order.totalUsdt}</p>
                        <p className="mb-1">Курс: {order && order.course} (baybit: {order && order.metadata.origin_course})</p>
                        
                        <p className="mb-1">
                          Время создания: {order && order.createdAt}
                        </p>
                      </div>
                      <div className="form-group">
                        <label style={{ fontSize: 16 }} htmlFor="status">
                          <strong>Иземнить статус на:</strong>
                        </label>
                        <select
                          onChange={updateField}
                          value={adminCunsumer.status ? adminCunsumer.status : ''}
                          className="form-control"
                          name="status"
                          id="status"
                          required
                        >
                          <option defaultValue>
                            Выбирите статус
                          </option>
                          <option value={"ERROR"}>Ошибочная</option>
                          <option value={"COMPLETED"}>Выполненная</option>
                          <option value={"PENDING"}>В работе</option>
                        </select>
                      </div>
                      
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  ref={simulateClick}
                  onClick={props.togglePopUp}
                  type="button"
                  className="btn btn-gray btn-small"
                  data-dismiss="modal"
                >
                  Закрыть
                </button>
                <button
                  onClick={(e) => submit(e)}
                  className={`btn btn-green btn-small`}
                >
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChangeStatusModal;