import React, { useContext, useEffect, useCallback } from "react";
import { Redirect } from "react-router-dom";
import { isEmpty } from "lodash";
import moment from "moment";
import DataTable, {createTheme} from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import Checkbox from '@material-ui/core/Checkbox';
import 'react-data-table-component-extensions/dist/index.css';

import Loader from "../../components/Loader";
import AdminBar from "../../components/AdminBar";
import Filter from "../../components/forms/FilterAdmin";
import SummSelected from "../../components/divs/SummSelected";
import ModalEditShowElOrder from "../../components/modals/ModalEditShowElOrder";
import CheckModal from "../manager/components/CheckModal";
import { sendNotification } from "../../helpers/notification";
import { bank_list } from "../../helpers/banks";

import { AdminContext } from "../../context/admin-context";
import { ManagerContext } from "../../context/manager-context";
import { OrderContext } from "../../context/orders-context";
import { UserContext } from "../../context/users-context";

import { FileText, AlignJustify } from "react-feather";

const Archive = (props) => {

  const [loading, setLoading] = React.useState(true);
  const [showSearch, setShowSearch] = React.useState(true);
  const [clearOrderArray, setClearOrderArray] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [redirectPath, setRedirectPath] = React.useState("");
  const [modalChangeElOrder, setModalChangeElOrder] = React.useState(false);
  const [objShowElOrder] = React.useState(false);
  const [showCheck, setShowCheck] = React.useState(false);
  const [filter, setFilter] = React.useState({
    bank: '',
    status: '',
    min_sum: null,
    max_sum: null,
    limit: null,
    manager_id: null,
    start_date: null,
    end_date: null,
  });
  // const adminCunsumer = useContext(AdminContext);
  const managerCunsumer = useContext(ManagerContext);
  const OrderCunsumer = useContext(OrderContext);
  const userCunsumer = useContext(UserContext);

  useEffect(() => {
    setLoading(false);
  }, [loading]);

  useEffect(() => {
    // adminCunsumer.getSettings();
    OrderCunsumer.getOrders();
    // adminCunsumer.getUsersShortList();
    return () => OrderCunsumer.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.pathname]); 

  // useEffect(() => {
  //   adminCunsumer.getSettings();
  //   return () => adminCunsumer.clear();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [adminCunsumer.rerenderSettings]); 

  useEffect(() => {
    if (OrderCunsumer.redirect === true) {
      sendNotification("warning", "Ошибка ⚠️", OrderCunsumer.redirectMessage);
      setRedirectPath(OrderCunsumer.redirectPath);
      OrderCunsumer.clear();
      setRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OrderCunsumer.redirect]);

  const togglePopUp = useCallback(
    (trigger) => {
      setShowCheck(false);
      managerCunsumer.updateState('checkFile', '');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showCheck]); 

  const togglePopUpShowElOrder = useCallback(
    (trigger) => {
      setModalChangeElOrder(!modalChangeElOrder);
    },[modalChangeElOrder]
  );

  const updateData = () => {
    OrderCunsumer.clear();
    setTimeout(() => {
        OrderCunsumer.getOrders(filter);
    }, 200);
  };

  const downloadInvoice = (id) => {
    managerCunsumer.getCheck(id);
    setShowCheck(true);
  }

  const getStatusBadge = (status) => {
    switch (status) {
      case "COMPLETED":
        return "btn-line-success"
      case "PENDING":
        return "btn-line-primary"
      case "ERROR":
        return "btn-line-danger"
      case "MARKED_AS_COMPLETED":
        return "btn-line-info"
      default:
        return "btn-line-warning"
    }
  }
  
  const getStatus = (status) => {
    switch (status) {
      case "COMPLETED":
        return "Выполненная"
      case "PENDING":
        return "В работе"
      case "ERROR":
        return "Ошибочная"
      case "MARKED_AS_COMPLETED":
        return "Выполнил трейдер"
      default:
        return "Новая"
    }
  }

  let columns = [
    { name: "id",
      sortable: true,
      width: "80px",
      selector: (row) => row.id,
      cellExport: row => row.id,
    },
    { name: "External ID",
      selector: (row) => row.externalId,
      sortable: true,
      width: "200px",
      cellExport: (row) => row.externalId,
      cell: (row) => {
        return `${row.externalId}`;
      },
    },
    { name: "Статус",
      sortable: true,
      width: "180px",
      selector: (row) => row.status,
      cellExport: row => row.status,
      cell: (row) => {
        return (
          <button
            type="button"
            style={{ fontSize: 11 }}
            className={`btn-line ${getStatusBadge(row.status)}`}
          >
            {getStatus(row.status)}
          </button>
        );
      },
    },
    { name: "Карта",
      sortable: true,
      width: "250px",
      selector: (row) => row.card,
      cellExport: row => row.card,
      cell: (row, index) => row.card,
    },
    { name: "Банк",
      sortable: true,
      width: "120px",
      selector: (row) => row.bank,
      export: false,
      cell: (row) => {
        let bankName = bank_list.find((item) => item.schema === row.bank)?.bankName;
        if (isEmpty(row.bank)) {
          return "Не указан";
        } else if (!isEmpty(bankName)) {
          return bankName;
        } else {
          return row.bank;
        }
      },
    },
    { name: "Cумма в RUB",
      sortable: true,
      width: "120px",
      selector: (row) => row.amount,
      cellExport: (row) => row.amount,
      cell: (row) => {
        return `${row.amount}`;
      },
    },
    { name: 'Cумма в USDT',
      selector: (row) => row.totalUsdt,
      sortable: true,
      width: "130px",
      cellExport: (row) => row.totalUsdt,
      cell: (row) => row.totalUsdt,
    },
    { name: "Курс",
      selector: (row) => row.course,
      sortable: true,
      width: '100px',
      cell: (row) => {
        return `${row.course}`;
      },
    },
    { name: "ФИО клиента:",
      width: "200px",
      selector: (row) => row.clientName,
      cellExport: (row) => row.clientName,
    },
    { name: "Callback url",
      selector: (row) => row.callbackUrl,
      sortable: true,
      width: "400px",
      cellExport: (row) => row.callbackUrl,
      cell: (row) => {
        return `${row.callbackUrl}`;
      },
    },
    { name: "Дата создания",
      sortable: true,
      width: "195px",
      selector: (row) => row.createdAt,
      cellExport: (row) => row.createdAt,
      cell: (row) => {
        return (
          <span>{moment(row.createdAt).format("D/MM/YYYY, H:mm:ss")}</span>
        );
      },
    },
    { name: "Выполненная в",
      sortable: true,
      width: "195px",
      selector: (row) => row.successedAt,
      cellExport: (row) => row.successedAt,
      cell: (row) => {
        return (
          <span>{row.successedAt ? moment(row.successedAt).format("D/MM/YYYY, H:mm:ss") : '-------'}</span>
        );
      },
    },
  ];

  createTheme('solarized', {
    text: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    background: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#073642',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const renderRedirect = () => {
    if (redirect && redirectPath) {
      return (
        <Redirect to={{ pathname: redirectPath,}}/>
      );
    }
  };

  const hendleSelected = async (state) => {
    await setShowSearch(false);
    await OrderCunsumer.updateState('orderArray', state.selectedRows);
    if (isEmpty(OrderCunsumer.orderArray)) {
      await setShowSearch(true);
    }
  }

  const handleClearSelected = async (bool) => {
    await setClearOrderArray(true);
    await OrderCunsumer.updateState('orderArray', []);
    await setShowSearch(true);
    await setClearOrderArray(false);
  }

  const tableData = {
    columns: columns,
    data: OrderCunsumer.orders,
    export: false,
    print: false,
    filter: showSearch,
    filterPlaceholder: 'Универсальный поиск...'
  };

  return (
    <>
      {renderRedirect()}
      {loading && <Loader/>}
      <div className="main-wrapper">
        <AdminBar />
        <div className="page-wrapper" style={{backgroundColor: '#070d19'}}>
          <nav  className="navbar" style={{height: '60px'}}>
            <div  className="navbar-content">
              <form  className="search-form">
                <div  className="input-group">
                  <div className=" d-flex justify-content-between align-items-center flex-wrap w-100">
                    <div>
                      <span className="mt-4 mb-md-0 sub-title" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                        <AlignJustify className="link-icon mb-1 me-3 icon-menu-mobil-media" style={{width: '18px'}} onClick = { () => {userCunsumer.setSidebarOpen(true)}}/>
                        Архив всех заявок
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </nav>
          <div className="page-content">
            <div className="row">
              <div className="col-12 col-xl-12">
                <div className="row">
                  <div className="col-md-7 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <Filter updateFilter={setFilter} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 grid-margin">
                    <SummSelected handleClearSelected={() => handleClearSelected() } />
                    {/* <div className="card w-75">
                      <div className="card-body">
                        <div className="button-edit-order-view" style={{cursor: 'pointer'}} onClick={() => setModalChangeElOrder(!modalChangeElOrder)}>
                          <h6 className="button-edit-order-title" style={{textTransform: 'none'}}>
                            <EyeOff className="link-icon mr-2 " style={{position: 'relative', top: '-2px'}} size={17} /> Скрыть элементы
                          </h6>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <ModalEditShowElOrder
                  updateData={() => updateData()}
                  togglePopUpShowElOrder={togglePopUpShowElOrder}
                  show={modalChangeElOrder}
                  objShowElOrder={objShowElOrder}
                />
                <CheckModal
                  togglePopUp={togglePopUp}
                  show={showCheck}
                />
                <div className="row ">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <h6 className="card-title text-primary">
                            Последние заявки
                            <FileText className="link-icon ms-1 mb-1" style={{width: '16px'}}/>
                        </h6>
                        {isEmpty(OrderCunsumer.orders) ? (
                          <div className="text-center">
                            <h5 className="text-muted py-3" style={{textTransform: 'none'}}>Заявок пока нету...</h5>
                          </div>
                        ) : (
                          <div className="table-responsive">
                            <div
                              id="dataTableExample_wrapper"
                              className="dataTables_wrapper dt-bootstrap4 no-footer"
                            >
                              <DataTableExtensions {...tableData} >
                                <DataTable
                                  title={false}
                                  columns={columns}
                                  highlightOnHover={true}
                                  striped={true}
                                  pointerOnHover={true}
                                  className={"table dataTable"}
                                  pagination={true}
                                  noHeader={true}
                                  theme="solarized"
                                  paginationPerPage={50}
                                  selectableRows={true}
                                  selectableRowsComponent={Checkbox}
                                  selectableRowsComponentProps={{ color: 'primary' }}
                                  selectableRowsVisibleOnly={true}
                                  clearSelectedRows={clearOrderArray}
                                  selectableRowsHighlight={true}
                                  onSelectedRowsChange={hendleSelected}
                                  paginationRowsPerPageOptions={[
                                    50,
                                    100,
                                    200,
                                    300,
                                    400,
                                  ]}
                                />
                              </DataTableExtensions>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Archive;